export default function WonderfulPistachiosLogo(props: {
  className?: string;
  dataTestId?: string;
}) {
  return (
    <svg
      className={props.className}
      width="114"
      height="35"
      viewBox="0 0 114 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid={props.dataTestId}
    >
      <title>Wonderful Pistachios Logo</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-188.000000, -61.000000)" fillRule="nonzero">
          <g transform="translate(188.808000, 61.613000)">
            <g transform="translate(22.438000, 0.000000)" fill="#FFFFFF">
              <path d="M0,0.874 L1.35323715,0.874 L3.69699478,9.43372195 L3.72619629,9.43372195 L6.25976181,0.874 L7.6289711,0.874 L10.1465645,9.43372195 L10.1762199,9.43372195 L12.5637817,0.874 L13.8449364,0.874 L10.8172493,11.1816191 L9.49229813,11.1816191 L6.94368362,2.44532864 L6.91448212,2.44532864 L4.35263062,11.1816191 L3.01262283,11.1816191 L0,0.874 Z"></path>
              <path d="M20.6735429,5.06589418 C21.1539811,5.79407125 21.3145722,6.76953607 21.1101731,7.61405569 C20.4408616,10.4528485 17.5724076,11.3416243 17.456067,11.3699122 C17.3397187,11.3416243 14.4712647,10.4528485 13.8019456,7.61405569 C13.5975465,6.76953607 13.7576875,5.79407125 14.2385835,5.06589418 C14.6610688,4.45543009 15.2715348,4.09042936 16.0736283,4.11962992 C16.6363318,4.13940086 17.0971447,4.39687862 17.456067,4.8920632 C17.8149842,4.39687862 18.2759497,4.13940086 18.8389634,4.11962992 C19.6392182,4.09042936 20.251973,4.45543009 20.6735429,5.06589418 Z M20.0913667,7.36631113 C20.22231,6.73987871 20.1356171,6.2156468 19.8294801,5.8082152 C19.5680512,5.47423845 19.1893586,5.24063879 18.7810105,5.28398233 C18.2475073,5.33295287 17.8058595,5.760308 17.456067,6.56604772 C17.1065746,5.760308 16.6649216,5.33295287 16.1311082,5.28398233 C15.7090806,5.24063879 15.344983,5.47423845 15.0830964,5.8082152 C14.7765015,6.2156468 14.6893662,6.73987871 14.8207597,7.36631113 C15.2865876,9.52118402 17.3543137,10.2493611 17.456067,10.2639609 C17.5432099,10.2493611 19.6255311,9.52118402 20.0913667,7.36631113 Z"></path>
              <path d="M23.1184875,5.98378388 C23.1184875,5.35780827 23.061,4.81897656 23.061,4.3672878 L24.1373016,4.3672878 C24.1373016,4.73183266 24.1664993,5.11052052 24.1664993,5.48920838 L24.1961471,5.48920838 C24.5013839,4.81897656 25.3459045,4.193 26.4659988,4.193 C28.2572433,4.193 29.0584213,5.32860772 29.0584213,6.9733936 L29.0584213,11.1813957 L27.9223586,11.1813957 L27.9223586,7.08973615 C27.9223586,5.95504109 27.4273254,5.24100892 26.3647186,5.24100892 C24.8946639,5.24100892 24.2536346,6.30407254 24.2536346,7.86171357 L24.2536346,11.1813957 L23.1184875,11.1813957 L23.1184875,5.98378388 Z"></path>
              <path d="M36.8767322,9.97198794 L36.8466266,9.97198794 C36.4255145,10.7439635 35.4495929,11.3567087 34.2263873,11.3567087 C32.3192523,11.3567087 30.82,9.81184504 30.82,7.77468417 C30.82,5.70695469 32.2617572,4.19265768 34.4015735,4.19265768 C35.3907245,4.19265768 36.2215656,4.62929079 36.8466266,5.35746643 L36.8767322,5.35746643 L36.8767322,0.174 L38.0114368,0.174 L38.0114368,11.1810534 L36.8767322,11.1810534 L36.8767322,9.97198794 Z M34.4595264,5.2406666 C32.9894794,5.2406666 32.0432208,6.34661887 32.0432208,7.77468417 C32.0432208,9.20137999 32.9894794,10.3073323 34.4595264,10.3073323 C35.9300159,10.3073323 36.8767322,9.20137999 36.8767322,7.77468417 C36.8767322,6.34661887 35.9300159,5.2406666 34.4595264,5.2406666 Z"></path>
              <path d="M46.1946753,9.94358651 C45.3930396,10.9783632 44.4317206,11.3570511 43.1656226,11.3570511 C41.0258063,11.3570511 39.715,9.75469891 39.715,7.77502553 C39.715,5.66304032 41.2005499,4.193 43.2089575,4.193 C45.1320685,4.193 46.4419592,5.50335233 46.4419592,7.76042573 L46.4419592,8.12451379 L40.9386633,8.12451379 C41.0258063,9.30392281 41.9278143,10.3076746 43.1656226,10.3076746 C44.1415289,10.3076746 44.7957953,9.95818631 45.3350867,9.27381055 L46.1946753,9.94358651 Z M45.2187537,7.16319291 C45.1744879,6.0138971 44.4180334,5.24100892 43.1656226,5.24100892 C41.9141272,5.24100892 41.0842017,6.0138971 40.9386633,7.16319291 L45.2187537,7.16319291 Z"></path>
              <path d="M48.2038684,5.98378388 C48.2038684,5.35780827 48.145,4.81897656 48.145,4.3672878 L49.2221942,4.3672878 C49.2221942,4.73183266 49.2523303,5.11052052 49.2523303,5.48920838 L49.2810626,5.48920838 C49.5862994,4.81897656 50.4308124,4.193 51.5522876,4.193 C51.6973682,4.193 51.8579669,4.20851247 52.0181232,4.2655441 L51.9455676,5.41575162 C51.7562213,5.35780827 51.5381427,5.32860772 51.3341937,5.32860772 C49.9503894,5.32860772 49.3390002,6.30407254 49.3390002,7.86171357 L49.3390002,11.1813957 L48.2038684,11.1813957 L48.2038684,5.98378388 Z"></path>
              <path d="M53.9115652,5.32855368 L52.426,5.32855368 L52.426,4.36723328 L53.9115652,4.36723328 L53.9115652,2.83879304 C53.9115652,1.17895234 54.493276,0 56.328771,0 C56.6189626,0 56.9825796,0.0588563606 57.201116,0.174287841 L57.0418905,1.19400859 C56.7941335,1.10686469 56.5318044,1.04846454 56.2699026,1.04846454 C55.0905203,1.04846454 55.046697,1.90758491 55.046697,3.08699322 L55.046697,4.36723328 L56.7065481,4.36723328 L56.7065481,5.32855368 L55.046697,5.32855368 L55.046697,11.1813412 L53.9115652,11.1813412 L53.9115652,5.32855368 Z"></path>
              <path d="M63.8539414,9.56552448 C63.8539414,10.1905865 63.9132523,10.729875 63.9132523,11.181106 L62.8351273,11.181106 C62.8351273,10.8170198 62.805922,10.4387869 62.805922,10.060099 L62.7762589,10.060099 C62.4710221,10.729875 61.6265092,11.3567633 60.5050645,11.3567633 C58.7142625,11.3567633 57.914,10.2202419 57.914,8.57545795 L57.914,4.367 L59.0487046,4.367 L59.0487046,8.45865859 C59.0487046,9.59335555 59.544188,10.3073877 60.6081833,10.3073877 C62.0777421,10.3073877 62.7187638,9.24477996 62.7187638,7.68668117 L62.7187638,4.367 L63.8539414,4.367 L63.8539414,9.56552448 Z"></path>
              <path d="M66.111,0.174 L67.2470474,0.174 L67.2470474,11.1810534 L66.111,11.1810534 L66.111,0.174 Z"></path>
            </g>
            <g transform="translate(90.742000, 0.080000)" fill="#FFFFFF">
              <path d="M1.12692261,0 C1.74057007,0 2.2543335,0.480888397 2.2543335,1.11188355 C2.2543335,1.75519708 1.74057007,2.23654166 1.12692261,2.23654166 C0.51373291,2.23654166 0,1.75519708 0,1.1246585 C0,0.480888397 0.51373291,0 1.12692261,0 Z M1.12692261,2.07411662 C1.65344238,2.07411662 2.0741272,1.6589283 2.0741272,1.11188355 C2.0741272,0.5748761 1.65344238,0.162425309 1.12692261,0.162425309 C0.600860596,0.162425309 0.180236816,0.5748761 0.180236816,1.1246585 C0.180236816,1.6589283 0.600860596,2.07411662 1.12692261,2.07411662 Z M0.709014893,0.478150934 L1.18441772,0.478150934 C1.49377441,0.478150934 1.64111328,0.592669874 1.64111328,0.850907832 C1.64111328,1.08222726 1.49102783,1.18168989 1.29803467,1.20267752 L1.67169189,1.7734473 L1.47598267,1.7734473 L1.1210022,1.21773377 L0.889678955,1.21773377 L0.889678955,1.7734473 L0.709014893,1.7734473 L0.709014893,0.478150934 Z M0.889678955,1.05530837 L1.11187744,1.05530837 C1.2925415,1.05530837 1.46090698,1.04663959 1.46090698,0.841782838 C1.46090698,0.661563903 1.30761719,0.64012 1.16891479,0.64012 L0.889678955,0.64012 L0.889678955,1.05530837 Z"></path>
            </g>
            <g
              className="logo-pistachios-text"
              transform="translate(0.000000, 17.378000)"
            >
              <path
                d="M0,0.366 L4.26594591,0.366 C6.90718269,0.366 9.30386829,1.2999447 9.30386829,4.30709344 C9.30386829,7.45568353 6.6224823,8.28879434 4.59079599,8.28879434 L1.70637846,8.28879434 L1.70637846,14.7483929 L0,14.7483929 L0,0.366 Z M1.70637846,6.70423395 L4.59079599,6.70423395 C6.29763126,6.70423395 7.47567081,5.93225748 7.47567081,4.3271702 C7.47567081,2.72253305 6.29763126,1.95010263 4.59079599,1.95010263 L1.70637846,1.95010263 L1.70637846,6.70423395 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M12.046,0.366 L13.752377,0.366 L13.752377,14.7483929 L12.046,14.7483929 L12.046,0.366 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M24.1535833,2.90540695 C23.584644,1.99107742 22.5690267,1.58455658 21.5328786,1.58455658 C20.0295331,1.58455658 18.4655034,2.29585648 18.4655034,4.0022316 C18.4655034,5.32262421 19.1968801,5.91164398 21.4922711,6.64255524 C23.7269933,7.35385132 25.7992818,8.08567429 25.7992818,10.9094162 C25.7992818,13.7733002 23.3409993,15.1142235 20.7408254,15.1142235 C19.0750539,15.1142235 17.2874639,14.5658073 16.312,13.1235962 L17.7747419,11.9250259 C18.3842885,12.9812469 19.6033971,13.5296593 20.842571,13.5296593 C22.305309,13.5296593 23.9706304,12.6764755 23.9706304,11.0312309 C23.9706304,9.26372147 22.7720601,8.9589386 20.2124898,8.10575104 C18.1812589,7.43506241 16.6373059,6.5412674 16.6373059,4.14412689 C16.6373059,1.381073 19.0139196,0 21.5328786,0 C23.1379735,0 24.4989659,0.447124481 25.5556447,1.68584824 L24.1535833,2.90540695 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M38.2727337,1.95010263 L33.5190639,1.95010263 L33.5190639,14.7483929 L31.8122234,14.7483929 L31.8122234,1.95010263 L27.059,1.95010263 L27.059,0.366 L38.2727337,0.366 L38.2727337,1.95010263 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M43.0912416,0.366 L44.6963289,0.366 L50.8105409,14.7483929 L48.819925,14.7483929 L47.357187,11.2138203 L40.1657655,11.2138203 L38.6829469,14.7483929 L36.753,14.7483929 L43.0912416,0.366 Z M43.8632142,2.55965692 L43.8226106,2.55965692 L40.8364427,9.62925989 L46.7070253,9.62925989 L43.8632142,2.55965692 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M62.7974086,3.33200073 C61.9442286,2.17403412 60.5827784,1.58455658 59.2017054,1.58455658 C55.991981,1.58455658 53.7162013,4.40875244 53.7162013,7.55688095 C53.7162013,10.8888855 55.9714427,13.5296593 59.2017054,13.5296593 C60.9486995,13.5296593 62.3502879,12.8188248 63.366363,11.5185089 L64.6456904,12.5952606 C63.366363,14.3217125 61.4971003,15.1142235 59.2017054,15.1142235 C55.0776515,15.1142235 51.888,11.9250259 51.888,7.55688095 C51.888,3.33200073 54.9352946,0 59.2017054,0 C61.1517253,0 63.061584,0.670688629 64.2802272,2.25479126 L62.7974086,3.33200073 Z"
                fill="#94CC27"
              ></path>
              <path
                d="M67.022,0.366 L68.7288481,0.366 L68.7288481,6.46014291 L76.4075438,6.46014291 L76.4075438,0.366 L78.1139189,0.366 L78.1139189,14.7483929 L76.4075438,14.7483929 L76.4075438,8.04470331 L68.7288481,8.04470331 L68.7288481,14.7483929 L67.022,14.7483929 L67.022,0.366 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M81.69,0.366 L83.3959174,0.366 L83.3959174,14.7483929 L81.69,14.7483929 L81.69,0.366 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M86.139,7.55688095 C86.139,3.21018219 89.2876053,0 93.6548234,0 C98.0229722,0 101.171562,3.21018219 101.171562,7.55688095 C101.171562,11.9049492 98.0229722,15.1142235 93.6548234,15.1142235 C89.2876053,15.1142235 86.139,11.9049492 86.139,7.55688095 Z M99.3433762,7.55688095 C99.3433762,4.24586868 97.0274277,1.58455658 93.6548234,1.58455658 C90.2831345,1.58455658 87.9672013,4.24586868 87.9672013,7.55688095 C87.9672013,10.868351 90.2831345,13.5296593 93.6548234,13.5296593 C97.0274277,13.5296593 99.3433762,10.868351 99.3433762,7.55688095 Z"
                fill="#94CC00"
              ></path>
              <path
                d="M110.739583,2.90540695 C110.170629,1.99107742 109.155034,1.58455658 108.118871,1.58455658 C106.615514,1.58455658 105.051503,2.29585648 105.051503,4.0022316 C105.051503,5.32262421 105.782415,5.91164398 108.078267,6.64255524 C110.312993,7.35385132 112.384816,8.08567429 112.384816,10.9094162 C112.384816,13.7733002 109.926549,15.1142235 107.326375,15.1142235 C105.661046,15.1142235 103.873006,14.5658073 102.898,13.1235962 L104.360738,11.9250259 C104.969823,12.9812469 106.188939,13.5296593 107.428106,13.5296593 C108.890844,13.5296593 110.556615,12.6764755 110.556615,11.0312309 C110.556615,9.26372147 109.358052,8.9589386 106.798467,8.10575104 C104.766805,7.43506241 103.22286,6.5412674 103.22286,4.14412689 C103.22286,1.381073 105.59992,0 108.118871,0 C109.7235,0 111.084951,0.447124481 112.141179,1.68584824 L110.739583,2.90540695 Z"
                fill="#94CC00"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
